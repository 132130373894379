import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import striptags from 'striptags'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { Flex, Box } from '../../common/Grid'
import Container from '../../common/Container'
import Divider from '../../common/Divider'
import Image from '../../common/Image'

import {
  TextBlock,
  LinkBlock,
  HeaderBlock,
  ImageBlock
} from '..'


const SustabilityStyles = css`
  .gatsby-image-wrapper {
    max-width: 540px;
    margin-left: auto;
  }
`

const ImageRightTextLeftWrapper = styled.div`
  ${props => (props.modification.includes('bluebg')) ? tw`bg-light-blue pt-8 pb-6 -mt-12` : tw`bg-none`}
  ${props => (props.modification.includes('fullimage')) ? 'overflow:hidden;' : ''}
  ${props => (props.modification.includes('sustainability')) ? SustabilityStyles : null }
  ${props => (props.modification.includes('morepadding')) ? tw`pt-12 pb-16` : ''}
  ${tw`mb-20`}

  :lang(zh-hans) .gatsby-image-wrapper,
  :lang(zh-hant) .gatsby-image-wrapper {
    width: 375px;
  }

  :lang(zh-hans) .image,
  :lang(zh-hant) .image {
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  :lang(zh-hans) h2,
  :lang(zh-hant) h2 {
    font-family: "Montserrat", "DejaVu Sans", Verdana, sans-serif;
    font-weight: 400;
  }

  @media screen and (max-width: 64em) {
    :lang(zh-hans) .gatsby-image-wrapper,
    :lang(zh-hant) .gatsby-image-wrapper {
      margin-bottom: 64px;
    }
  }
`

const ImageRightTextLeftFigure = styled.figure`
  margin: 0;
  img {
    width: 100%;
  } 
  figcaption {
    ${tw`font-raleway pt-2 text-xs`}
    color: #4F5354;
  }
`

const ImageRightTextLeft = (props) => {
  const { modification, image, alt, left, className, grid, loading } = props;

  let paddingLeft = 0;
  let imageWidth = 620;
  if (modification.includes('fullimage')) {
    paddingLeft = [0,0,0,"20%"];
    imageWidth = 920;
  }

  let leftGrid = [1,1/2,1/2,1/2];
  let rightGrid = [1,1/2,1/2,1/2];

  if (grid === 'onethird') {
    leftGrid = [1,2/3,2/3,2/3];
    rightGrid = [1,1/3,1/3,1/3];
  }

  if (grid === 'twothird') {
    leftGrid = [1,1/3,1/3,1/3];
    rightGrid = [1,2/3,2/3,2/3];
  }

  return (
    <ImageRightTextLeftWrapper modification={modification} className={className} {...props}>
      <Container modification={modification} width={(modification.includes('fullimage')) ? '100%': "1240px"}>
        <Flex alignItems="center" flexWrap="wrap" flexDirection={['column-reverse', 'row', 'row', 'row']} mx={[0, -2, -2, -2]}>
          <Box width={leftGrid} px={[0,2,2,2]} pl={paddingLeft}>
            {left.map((block) => {
              // eslint-disable-next-line dot-notation
              switch (block['__typename']) {
                case 'DividerBlock':
                  return <Divider color={block.color} key={block.guid} />
                case 'TextBlock':
                  return <TextBlock modification={block.modification} text={block.text} key={block.guid} />
                case 'LinkBlock':
                  return <LinkBlock modification={block.modification} title={block.title} link={block.link} key={block.guid} snapchat={block.snapchat} gtag={block.gtag} ga={block.ga} appnexus={block.appnexus} />
                case 'HeaderBlock':
                  return <HeaderBlock modification={block.modification} title={block.title} type={block.type} key={block.guid} />
                case 'ImageBlock':
                  return <ImageBlock modification={block.modification} image={block.image_url} key={block.guid} inside />
                default:
                  // eslint-disable-next-line dot-notation
                  console.log(`BLOCK ${block['__typename']} NOT DEFINED`);
              }

              return null
            })}
          </Box>

          <Box width={rightGrid} px={[0, 2, 2, 2]} mb={[5,0,0,0]} className="image">
            { image ?
              (
                <ImageRightTextLeftFigure>
                  <Image
                    url="https://img.imageboss.me/royale-images/width/734/format:webp/images/Web_ClusterPacks_Purr-oudlyCanadian.jpg"
                    alt={striptags(alt)}
                    sizes={`(min-width: 64em) ${imageWidth}px, 100vw`}
                    loading={loading}
                  />
                  
                </ImageRightTextLeftFigure>
              )
              : null }
          </Box>
        </Flex>
      </Container>
    </ImageRightTextLeftWrapper>
  )
}

ImageRightTextLeft.propTypes = {
  modification: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.objectOf({
      base64: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string
    }),
    PropTypes.string
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  left: PropTypes.arrayOf([
    PropTypes.object
  ]).isRequired,

  className: PropTypes.string,
  grid: PropTypes.string,
  loading: PropTypes.string
}

ImageRightTextLeft.defaultProps = {
  modification: '',

  className: '',
  grid: 'half',
  loading: 'lazy'
}

export const imageRightTextLeftBlockFragment = graphql`
  fragment imageRightTextLeftBlockFragment on ImageRightTextLeftBlock {
    id
    modification
    image_url
    left {
      __typename

      ... on TextBlock {
        guid
        modification
        text
      }

      ... on LinkBlock {
        guid
        title
        modification
        link
      }

      ... on HeaderBlock {
        guid
        modification
        type
        title
      }

      ... on ImageBlock {
        guid
        modification
        image_url
      }
    }
  }
`

export default ImageRightTextLeft
